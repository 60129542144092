var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm._.isEmpty(_vm.selectedCurrency) && !_vm._.isEmpty(_vm.projectsToInvest))?_c('v-form',{ref:"formAddInvestments",model:{value:(_vm.formAddInvestmentsValid),callback:function ($$v) {_vm.formAddInvestmentsValid=$$v},expression:"formAddInvestmentsValid"}},[_c('v-row',_vm._l((_vm.projects),function(project,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mb-3",attrs:{"height":"100%"}},[_c('div',{staticClass:"primary px-3 py-1"},[_c('b',[_vm._v("Proyecto: ")]),_c('router-link',{staticClass:"black--text",attrs:{"to":`/proyectos/${project.id}`,"target":"_blank"}},[_vm._v(" "+_vm._s(project.id)+" ")])],1),_c('div',{staticClass:"primary px-3 py-1"},[_c('b',[_vm._v("Prestataria: ")]),(!project.borrower)?_c('span',[_vm._v("Sin prestataria")]):_c('router-link',{staticClass:"black--text",attrs:{"to":`/prestatarias/${project.borrower.id}`,"target":"_blank"}},[_vm._v(" "+_vm._s(project.borrower.name)+" ")])],1),_c('div',{staticClass:"primary px-3 py-1"},[_c('b',[_vm._v("Partner: ")]),_c('router-link',{staticClass:"black--text",attrs:{"to":`/partners/${project.partner.id}`,"target":"_blank"}},[_vm._v(" "+_vm._s(project.partner.name)+" ")])],1),_c('div',{staticClass:"primary px-3 py-1"},[_c('b',[_vm._v("Fecha de creación:")]),_vm._v(" "+_vm._s(_vm.formatDateL(project.created_at)))]),_c('div',{staticClass:"primary px-3 py-1"},[_c('b',[_vm._v("Sector:")]),_vm._v(" "+_vm._s(project.sector))]),_c('div',{staticClass:"primary px-3 py-1"},[_c('b',[_vm._v("Ya invertido por "+_vm._s(_vm.investor.first_name)+" "+_vm._s(_vm.investor.last_name)+":")]),_vm._v(" "+_vm._s(_vm.investments.some((e) => e.project_id === project.id) ? "Sí" : "No")+" ")]),_c('div',{staticClass:"primary px-3 py-1"},[_c('b',[_vm._v("Número de créditos solicitados:")]),_vm._v(" "+_vm._s(project.borrower && project.borrower.num_credits ? project.borrower.num_credits : 0)+" ")]),_c('v-card-text',[(
                _vm.commissionIsInCurrentVersion(project) &&
                (_vm.investor.investor.type === 'RETAIL' || !!_vm.getPartnerExchangeRate(project.partner.id))
              )?_c('div',[_c('div',{staticClass:"d-flex"},[_c('p',[_vm._v(" Inversión máxima: "+_vm._s(_vm.maxAmountToInvestInFavoriteCurrency(project))+" "+_vm._s(_vm.currencySymbol(_vm.favoriteCurrency))+" ≈ "+_vm._s(_vm.maxAmountToInvestInProjectCurrency(project))+" "+_vm._s(_vm.currencySymbol(project.currency))+" ")])]),_c('v-text-field',{staticClass:"amount_field mt-1 mb-n5",attrs:{"type":"number","label":"Cantidad","outlined":"","suffix":_vm.suffix(project),"rules":[
                  (v) => !!v || v === 0 || 'Campo obligatorio',
                  (v) => v >= 0 || 'Introduce un valor positivo',
                  _vm.enoughFundsRule(project),
                  (v) => v <= _vm.maxAmountToInvestInFavoriteCurrency(project) || 'Máximo de inversión superado'
                ]},model:{value:(_vm.projectsToInvest[project.id].amount),callback:function ($$v) {_vm.$set(_vm.projectsToInvest[project.id], "amount", _vm._n($$v))},expression:"projectsToInvest[project.id].amount"}},[_c('template',{slot:"append"},[_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":""},on:{"click":function($event){_vm.projectsToInvest[project.id].amount = _vm.maxAmountToInvestInFavoriteCurrency(project).value}}},[_vm._v(" max. ")])],1)],2)],1):_c('div',{staticClass:"text-center py-5 mb-0"},[(!_vm.commissionIsInCurrentVersion(project))?_c('p',[_vm._v(" Este proyecto no puede ser invertido aún ya que la versión activa de "),_c('i',[_vm._v("parámetros de inversión")]),_vm._v(" no contiene la comisión requerida para el partner de este proyecto "),_c('br'),_c('b',[_vm._v("Crea una nueva versión con la configuración necesaria en \"Ajustes Generales\"")])]):_c('p',[_vm._v(" Este proyecto no puede ser invertido aún ya que el inversor no tiene asignado un tipo de cambio para el partner del mismo ")])])])],1)],1)}),1),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"disabled":!_vm.getTotalToInvest() > 0 || !_vm.formAddInvestmentsValid},on:{"click":function($event){return _vm.investMultipleProjects()}}},[_vm._v("INVERTIR")])],1)],1):_c('v-col',[_c('v-card',{staticClass:"secondary",attrs:{"flat":""}},[_c('p',{staticClass:"text-center mb-0 pa-3 text--secondary"},[(_vm.investor.investor.type === 'RETAIL')?_c('span',[_vm._v(_vm._s(!_vm._.isEmpty(_vm.selectedCurrency) ? "No hay proyectos invertibles de la moneda seleccionada" : "Selecciona una moneda"))]):_c('span',[_vm._v(_vm._s(!_vm._.isEmpty(_vm.selectedCurrency) ? "No hay proyectos invertibles del partner seleccionado" : "Selecciona un partner"))])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }