<template>
  <v-container v-if="!_.isEmpty(investor)">
    <!-- Banner header -->
    <Banner :title="'Invertir en múltiples proyectos'" class="mb-5">
      <template v-slot:actions>
        <router-link :to="`/inversores/${investor.investor.type.toLowerCase()}/${investor.id}`">
          {{ investor.first_name }} {{ investor.last_name }}
        </router-link>
      </template>
    </Banner>

    <div v-if="!_.isEmpty(totalFunds)">
      <p>
        Cantidad total de las carteras en {{ investor.investor.favorite_currency }}:
        <b>{{ totalFundsInFavoriteCurrency }} {{ investor.investor.favorite_currency }}</b>
        <b v-if="investor.investor.type === 'RETAIL'">
          ({{ currency(totalFunds.available_funds, { fromCents: true }) }} {{ selectedCurrency }})
        </b>
        <b v-else>
          ({{ currency(totalFundsInFavoriteCurrency).multiply(getPartnerExchangeRate(selectedPartner)) }} {{ selectedCurrency }})
        </b>
      </p>
      <p>
        Total a invertir: <b>{{ getTotalToInvest() }}</b>
      </p>
      <p v-if="investor.investor.type === 'FUND'">
        Tipo de cambio: <b>{{ getPartnerExchangeRate(selectedPartner) }}</b>
      </p>
    </div>

    <v-card>
      <v-card-text class="black--text">
        <p class="text-subtitle-1 mb-0">
          Invesión atomática <b>{{ investor.investor.automatic_investment.active ? "activada" : "desactivada" }}</b>
        </p>
        <div v-if="investor.investor.automatic_investment.active">
          <p class="text-subtitle-2 mb-0">Preferencias</p>
          <ul>
            <li>Cantidad máxima: {{ investor.investor.automatic_investment.settings?.max_amount ?? "Cualquiera" }}</li>
            <li>Número de créditos concedidos: {{ investor.investor.automatic_investment.settings?.num_credits ?? "Todos" }}</li>
            <li
              v-if="
                investor.investor.automatic_investment.settings?.partners &&
                investor.investor.automatic_investment.settings?.partners.length > 0
              "
            >
              Partners:
              <ul>
                <li v-for="partnerId in investor.investor.automatic_investment.settings.partners" :key="partnerId">
                  {{ getPartnerName(partnerId) }}
                </li>
              </ul>
            </li>
            <li v-else>Partners: cualquiera</li>
          </ul>
        </div>
      </v-card-text>
    </v-card>

    <v-select
      v-if="investor.investor.type === 'RETAIL'"
      v-model="selectedCurrency"
      :items="currencies.filter((e) => e.id !== 'EUR')"
      item-text="name"
      item-value="id"
      label="Moneda"
      filled
      class="mt-5"
      @change="createObjectOfInvestableProjects()"
    />

    <v-select
      v-else
      v-model="selectedPartner"
      :items="partners"
      :item-disabled="(e) => !_.keys(investor.investor.exchange_rates).includes(e.id)"
      :item-text="(e) => (!_.keys(investor.investor.exchange_rates).includes(e.id) ? `${e.name} (comisión no asignada)` : e.name)"
      item-value="id"
      label="Partner"
      filled
      class="mt-5"
      @change="createObjectOfInvestableProjects()"
    />

    <!-- Projects to invest -->
    <ListProjectsToInvestSimple
      :investor="investor"
      :projectsToInvest="projectsToInvest"
      :selectedCurrency="selectedCurrency"
      :totalFunds="totalFunds"
      @invest="createObjectOfInvestableProjects()"
    />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Banner from "@/components/elements/Banner";
import CommonMixin from "@/mixins/CommonMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import _ from "lodash";
import listeners from "@/firebase/listeners";
import ListProjectsToInvestSimple from "@/components/elements/investor/invest-multiples-projects/ListProjectsToInvestSimple.vue";
import * as currency from "currency.js";

export default {
  name: "InvestMultiplesProjects",
  components: {
    Banner,
    ListProjectsToInvestSimple
  },
  mixins: [CommonMixin, FormRulesMixin, FormatDateMixin],
  data() {
    return {
      // Wallets datatable
      tableHeaders: [
        { text: "Moneda", value: "currency", sortable: false },
        { text: "Disponible", value: "available_funds", sortable: false },
        { text: "A invertir", value: "to_invest", sortable: false },
        { text: "Restante", value: "substract", sortable: false }
      ],

      wallets: [], // Investor's available wallets
      selectedCurrencies: [], // Array of currencies selected for invest in its projects
      projectsToInvest: {}, // Object with projects and its amounts to send to the endpoint
      totalFunds: {}, // Total funds available, withheld, etc...
      selectedCurrency: "",
      selectedPartner: ""
    };
  },
  computed: {
    ...mapState({
      investor: (state) => state.investors.investor,
      projects: (state) => state.projects.projects,
      currencies: (state) => state.currencies.currencies,
      partners: (state) => state.partners.partners
    }),
    _() {
      return _;
    },
    currency() {
      return currency;
    },

    totalFundsInFavoriteCurrency() {
      if (this.investor.investor.type === "FUND") {
        return (
          currency(this.totalFunds.available_funds, { fromCents: true }).value * (1 / this.getPartnerExchangeRate(this.selectedPartner))
        );
      } else {
        return this.convertCurrency(
          currency(this.totalFunds.available_funds, { fromCents: true }),
          this.selectedCurrency,
          this.investor.investor.favorite_currency
        );
      }
    }
  },
  async created() {
    await listeners.investor(this.$route.params.id);
    await this.$store.dispatch("investments/getInvestments", {
      filter: { "investor.id": this.$route.params.id }
    });
    await this.$store.dispatch("investments/getInvestmentsSettings");
    await this.$store.dispatch("currencies/getCurrencies");
    await this.$store.dispatch("partners/getPartners");
  },
  methods: {
    // Create object of objects of each project possible to invest
    async createObjectOfInvestableProjects() {
      this.projectsToInvest = {};

      if (this.investor.investor.type === "FUND") {
        this.selectedCurrency = this.partners.find((e) => e.id === this.selectedPartner)?.country.currency;

        this.totalFunds = await this.$store.dispatch("investors/getTotalFunds", {
          investor_id: this.investor.id,
          currency: this.selectedCurrency,
          main_wallet_exchange_rate: this.getPartnerExchangeRate(this.selectedPartner)
        });
      } else {
        this.totalFunds = await this.$store.dispatch("investors/getTotalFunds", {
          investor_id: this.investor.id,
          currency: this.selectedCurrency
        });
      }

      const filter = {
        status: "INVESTABLE",
        currency: this.selectedCurrency,
        to_bag: false
      };
      if (this.investor.investor.type === "FUND") filter["partner.id"] = this.selectedPartner;

      await this.$store.dispatch("projects/getProjects", { filter });

      for (let i = 0; i < this.projects.length; i++) {
        const project = this.projects[i];

        const params = {
          investor_id: this.investor.id,
          project_id: project.id,
          amount: 0
        };

        this.$set(this.projectsToInvest, project.id, params);
      }
    },

    // Obtain the sum of the investments to be made
    getTotalToInvest() {
      let totalInFavoriteCurrency = 0;
      for (const projectId of this._.keys(this.projectsToInvest)) {
        totalInFavoriteCurrency += this.projectsToInvest[projectId].amount;
      }

      let totalInSelectedCurrency = 0;

      if (this.investor.investor.type === "FUND") {
        totalInSelectedCurrency = currency(totalInFavoriteCurrency).multiply(this.getPartnerExchangeRate(this.selectedPartner));
      } else {
        totalInSelectedCurrency = this.convertCurrency(
          currency(totalInFavoriteCurrency),
          this.investor.investor.favorite_currency,
          this.selectedCurrency
        );
      }

      return `${totalInFavoriteCurrency} ${this.investor.investor.favorite_currency} (${totalInSelectedCurrency} ${this.selectedCurrency})`;
    },

    // Get the exchange rate of a partner assigned to a fund investor
    getPartnerExchangeRate(partnerId) {
      return this.investor.investor.exchange_rates[partnerId];
    },

    getPartnerName(partnerId) {
      return this.partners.find((e) => e.id === partnerId)?.name;
    }
  }
};
</script>
